// 404ErrorPage.js
import React from "react";
import { FileExclamationOutlined, FileUnknownOutlined } from '@ant-design/icons';

const NotFoundPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50 flex items-center justify-center p-4">
            <div className="max-w-2xl w-full text-center space-y-8">
                {/* Animated Icon Group */ }
                <div className="relative h-40 mb-8">
                    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                        <FileExclamationOutlined className="text-[96px] text-indigo-500 animate-pulse" />
                    </div>
                    <FileUnknownOutlined className="absolute left-[20%] top-1/2 -translate-y-1/2 text-[48px] text-purple-400 animate-bounce" />
                    <FileUnknownOutlined className="absolute right-[20%] top-1/2 -translate-y-1/2 text-[48px] text-purple-400 animate-bounce delay-100" />
                </div>

                {/* Error Message */ }
                <div className="space-y-4">
                    <h1 className="text-6xl font-bold text-indigo-600 tracking-tight sm:text-7xl">
                        404
                    </h1>
                    <h2 className="text-3xl font-semibold text-gray-900 sm:text-4xl">
                        Page Not Found
                    </h2>
                    <p className="text-lg text-gray-600 max-w-lg mx-auto">
                        Oops! It seems like this resource is no longer available or has been moved to a different location.
                    </p>
                </div>

                {/* Help Text */ }
                <p className="text-sm text-gray-500 mt-8">
                    Contact the admin or support team for help.
                </p>
            </div>
        </div>
    );
};

export default NotFoundPage;