import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import MultiStepForm from '../../MultiStepForm/MainStepForm';
import GoogleAnalyticsApp from "../../api/FetchAnalyticsReport";
import { GoogleOAuthProvider } from "@react-oauth/google";
import InitializeGoogleAnalytics from "../GoogleAnalytics/index";
import { CLIENT_ID } from '../constants/constants';
import '../../styles.css';
import EventAnalyticsData from "../../api/FetchEventData";
import FormSelector from "../../MultiStepForm/FormSelector/FormSelector";
import Review from "../../MultiStepForm/ReviewPage/Review";
import ThankYouPage from "../../MultiStepForm/ThankYouPage/ThankYou";
import TestTermsAndConditions from "../../MultiStepForm/TermsAndConditions/TermsAndConditions";
import SignIn from "../../MultiStepForm/SignInPage/Signin";
import Instructions from "../../MultiStepForm/InstructionPage/Instructions";
import CouponCodeVerification from "../../MultiStepForm/CouponCode/VerificationCouponCode";
import TestSelection from "../../MultiStepForm/TestsSectionSelection/TestSelection";
import NotFoundPage from "../../MultiStepForm/404NotFound/404ErrorPage";

const RoutesContainer = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:client_name/:test_type/:testId" element={ <MultiStepForm /> } />
                <Route path="/:client_name/test/sign_in_page" element={ <SignIn /> } />
                <Route path="/:client_name/test/:grade/:id" element={ <MultiStepForm /> } />
                <Route path="/:client_name/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/test/selection" element={ <TestSelection /> } />
                <Route path="/:client_name/test/:grade/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/test/:grade/instructions" element={ <Instructions /> } />
                <Route path="/:client_name/verification/coupon_code" element={ <CouponCodeVerification /> } />
                <Route path="/google_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <GoogleAnalyticsApp />
                    </GoogleOAuthProvider> } />

                <Route path="/event_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <EventAnalyticsData />
                    </GoogleOAuthProvider> } />
                <Route path="/ani" element={ <Review /> } />
                <Route path="*" element={ <NotFoundPage /> } />
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesContainer;