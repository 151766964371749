import { useState } from 'react';
import axios from 'axios';
import { BASE_URL, FETCH_FLOW_BLOCKS_BY_ID } from '../utils/constants/constants';

const useFetchFlowBlocks = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const FETCH_DATA = `${ BASE_URL }${ FETCH_FLOW_BLOCKS_BY_ID }`

    const FetchFlowBlocks = async ( testId ) => {
        setIsLoading( true );
        try {
            const response = await axios.get( `${ FETCH_DATA }${ testId }/` );
            setResponse( response.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            console.log( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, FetchFlowBlocks };
};

export default useFetchFlowBlocks;